<template>
  <a-input :id="id" :type="type" :disabled="disabled" :placeholder="placeholder" v-model="curValue" @keyup.enter="sermethod" @blur="blurMethod" />
</template>

<script>
  export default {
    name: "InputLimit",
    props: {
      value:{
        type:String|Number,
        default: ''
      },
      type:{
        type:String,
        default:'text'
      },
      placeholder:{
        type:String,
        default:''
      },
      disabled:{
        type:String|Boolean,
        default:false
      },
      length:{
        type:String|Number,
        default:''
      },
      regex:{
        type:String,
        default:''
      }
    },
    data() {
      return {
        id:this.uuid(),
        composing:true,
        curValue:this.value
      };
    },
    watch:{
      value(val){
        this.curValue = val;
        setTimeout(()=>{
          this.$emit('changMethod');
        },500)
      },
    },
    methods:{
      init(){
        let _this = this;
        let input = document.getElementById(_this.id);
        input.addEventListener("compositionstart",()=>{
          _this.composing = false;
        });
        input.addEventListener("compositionend",(e)=>{
          _this.composing = true;
        });
        input.addEventListener("input", ()=>{
          setTimeout(()=>{
            if(_this.composing){
              _this.checkLength();
              _this.checkReg();
              _this.$emit('input',_this.curValue);

            }
          },100);
        });
      },
      checkLength(){
        if(this.length && this.curValue.length > this.length){
          this.curValue = this.curValue.substring(0,this.length);
        }
      },
      checkReg(){
        let regexp = new RegExp(this.regex);
        let value = this.curValue;
        this.recursionCheck(regexp,value)
      },
      recursionCheck(regexp,value){
        if(!value){
          this.curValue = '';
        }else{
          if(!(regexp.test(value))){
            value = value.substring(0,value.length-1);
            this.recursionCheck(regexp,value);
          }else{
            this.curValue = value;
          }
        }

      },
      uuid(){
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
          return 'id'+v.toString(16);
        });
      },
      sermethod(){
        this.$emit('enterSerch');
      },
      blurMethod(){
        this.$emit('blurMethod');
      },
    },
    mounted() {
      this.$nextTick(function () {
        this.init();
      })
    }
  }
</script>

<style scoped>

</style>
