<template>
  <div>
    <div class="filter">
      <a-row>
        <a-col :xxl="24" :xl="24" :lg="24" class="flex">
          <a-row>
            <a-col :xl="7" :lg="9" :sm="12" :xs="24" class="flex">
              <label>考试日期：</label>
              <a-date-picker
                class="flex1"
                :allowClear="true"
                v-model="filter.cdExamDayFrom"
                placeholder="开始"
                format="YYYY-MM-DD"
              />
              <span class="center-line">--</span>
              <a-date-picker
                class="flex1"
                :allowClear="true"
                v-model="filter.cdExamDayTo"
                placeholder="结束"
                format="YYYY-MM-DD"
              />
            </a-col>
            <getCard @card="getCardKey" v-if="showGetCard" />
            <a-col :xl="6" :lg="5" :sm="12" :xs="24" class="flex">
              身份证号：
              <a-input
                class="flex1"
                v-model="filter.cdIdCard"
                placeholder="身份证号"
              ></a-input>
            </a-col>
            <a-col :xl="7" :lg="5" :sm="12" :xs="24" class="flex">
              <label class="type-name">套餐名：</label>
              <a-select
                v-model="filter.cdHotMealNameList"
                mode="multiple"
                class="flex1"
                placeholder="选择套餐"
                option-label-prop="label"
              >
                <a-select-option
                  :value="hot.trainType"
                  :label="hot.trainType"
                  v-for="hot in hotList"
                  :key="hot.id"
                >
                  {{ hot.trainType }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col :xl="4" :lg="5" :sm="12" :xs="24" class="flex">
              <label class="type-name">收费员：</label>
              <a-input
                class="flex1"
                v-model="filter.cdChargerName"
                placeholder="收费员"
              ></a-input>
            </a-col>
            <a-col :xl="7" :lg="9" :sm="12" :xs="24" class="flex">
              收费日期：
              <a-date-picker
                class="flex1"
                :allowClear="true"
                v-model="filter.cdChargeDayFrom"
                placeholder="开始"
                format="YYYY-MM-DD"
              />
              <span class="center-line">--</span>
              <a-date-picker
                class="flex1"
                :allowClear="true"
                v-model="filter.cdChargeDayTo"
                placeholder="结束"
                format="YYYY-MM-DD"
              />
            </a-col>
            <a-col :xl="3" :lg="5" :sm="12" :xs="24" class="flex">
              <label class="type-name">姓名：</label>
              <a-input
                class="flex1"
                v-model="filter.cdStudentName"
                placeholder="姓名"
              ></a-input>
            </a-col>
            <a-col :xl="3" :lg="5" :sm="12" :xs="24" class="flex">
              <label class="type-name">业务员：</label>
              <!-- <a-input class="flex1" v-model="filter.cdHotAgentName" placeholder="业务员"></a-input> -->
              <a-select show-search v-model="filter.hotAgentName" class="flex1">
                <a-select-option
                  v-for="sale in saleList"
                  :key="sale.id"
                  :value="sale.hotAgentName"
                >
                  {{ sale.hotAgentName }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col :xl="3" :lg="5" :sm="12" :xs="24" class="flex">
              车号：
              <!-- <a-input class="flex1" v-model.number="filter.cdPracticeNum" placeholder="数量"></a-input> -->
              <input-limit
                class="flex1"
                :value="filter.cdCarHotCode"
                type="input"
                placeholder="车辆编号"
                :length="10"
                regex="^[0-9]*$"
              />
            </a-col>
            <a-col :xl="4" :lg="6" :sm="12" class="flex">
              是否补考：
              <a-select v-model="filter.cdIsMultiExam" class="flex1">
                <a-select-option value="">
                  所有
                </a-select-option>
                <a-select-option value="0">
                  否
                </a-select-option>
                <a-select-option value="1">
                  是
                </a-select-option>
              </a-select>
            </a-col>
            <a-col :xl="4" :lg="6" :sm="12" class="flex">
              支付方式：
              <a-select v-model="filter.cdChargeWay" class="flex1">
                <a-select-option value="">
                  所有
                </a-select-option>
                <a-select-option value="微信">
                  微信
                </a-select-option>
                <a-select-option value="支付宝">
                  支付宝
                </a-select-option>
                <a-select-option value="现金">
                  现金
                </a-select-option>
                <a-select-option value="POS机">
                  POS机
                </a-select-option>
              </a-select>
            </a-col>
            <a-col :xl="4" :lg="6" :sm="12" class="flex">
              <label class="type-name">状态：</label>
              <a-select v-model="filter.cdStatusId" class="flex1">
                <a-select-option value="">
                  所有
                </a-select-option>
                <a-select-option value="1">
                  正常
                </a-select-option>
                <a-select-option value="2">
                  作废
                </a-select-option>
              </a-select>
            </a-col>
            <a-col :xl="4" :lg="6" :sm="12" class="flex">
              <label class="type-name">考试结果：</label>
              <a-select v-model="filter.cdExamPass" class="flex1">
                <a-select-option value="">
                  所有
                </a-select-option>
                <a-select-option value="合格">
                  合格
                </a-select-option>
                <a-select-option value="不合格">
                  不合格
                </a-select-option>
                <a-select-option value="缺考">
                  缺考
                </a-select-option>
              </a-select>
            </a-col>
            <a-col
              :xl="16"
              :lg="24"
              :sm="24"
              style="margin: 5px 0; text-align: right;"
            >
              <a-button
                type="default"
                class="clear-btn dis"
                @click="clearFilter"
              >
                清空
              </a-button>
              <a-button
                type="primary"
                class="dis"
                @click="
                  filter.pageNum = 1
                  getStuList()
                "
              >
                查询
              </a-button>
              <a-button
                type="default"
                class="dis"
                icon="download"
                @click="exportStu"
              >
                导出数据
              </a-button>
              <a-button
                type="danger"
                @click="exportStuTxt"
                icon="cloud-download"
              >
                导出TXT
              </a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <!-- 总数据展示 -->
    <div class="total-con">
      为您找到
      <span class="total-num">{{ total }}</span>
      条数据 训练数量:
      <span class="total-num">{{ totalPracticeNum }}</span>
      &nbsp;&nbsp; 总收款:
      <span class="total-num">￥{{ totalMoney }}</span>
      &nbsp;&nbsp; 合格率:
      <span class="total-num">{{ (passRate * 100).toFixed(1) }}%</span>
    </div>
    <a-table
      :scroll="{ x: 1800 }"
      :columns="roleColums"
      :data-source="stuList"
      :pagination="false"
    >
      <span slot="amount" slot-scope="text, record">￥{{ text }}</span>
      <span slot="cancelId" slot-scope="text, record">
        <span v-if="!record.cancelName">否</span>
        <a-tooltip v-else placement="rightBottom">
          <template slot="title">
            <p>{{ record.cancelName }}</p>
            <p>{{ record.cancelDt }}</p>
          </template>
          <a-button>已作废</a-button>
        </a-tooltip>
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click.prevent="printStu(record)">打印</a>
        <a-divider type="vertical" />
        <span class="delete-btn" @click.prevent="recordGrade(record)">
          录入成绩
        </span>
        <a-divider type="vertical" />
        <span class="delete-btn" @click="cancelPay(record)">作废</span>
      </span>
    </a-table>
    <record-grade
      v-if="showGrade"
      :stu="curStu"
      @hideGrade="
        showGrade = false
        getStuList()
        curStu = {}
      "
    />
    <div style="padding-top: 20px;">
      <a-pagination
        style="float: right; text-align: center;"
        @change="changPage"
        :total="total"
        :pageSize="filter.pageSize"
      />
    </div>
    <!-- 打印小票 -->
    <print-ticket :stu="stu" v-if="showPrint" />
  </div>
</template>
<script>
const roleColums = [
  {
    title: '姓名',
    dataIndex: 'studentName',
    key: 'studentName',
    fixed: 'left',
    width: 80,
  },
  {
    title: '身份证',
    dataIndex: 'idCard',
    key: 'idCard',
    width: 165,
  },
  {
    title: '流水号',
    dataIndex: 'billNo',
    key: 'billNo',
    width: 160,
  },
  {
    title: '车号',
    dataIndex: 'carHotCode',
    key: 'carHotCode',
    width: 50,
  },
  {
    title: '车型',
    dataIndex: 'licName',
    key: 'licName',
    width: 50,
  },
  {
    title: '业务员',
    dataIndex: 'hotAgentName',
    key: 'hotAgentName',
    width: 80,
  },
  {
    title: '成绩',
    dataIndex: 'examScore',
    key: 'examScore',
    width: 70,
  },
  {
    title: '是否合格',
    dataIndex: 'examPass',
    key: 'examPass',
    width: 78,
  },
  {
    title: '套餐',
    dataIndex: 'hotMealName',
    key: 'hotMealName',
    width: 100,
  },
  {
    title: '考试日期',
    dataIndex: 'examDay',
    key: 'examDay',
    width: 98,
  },
  {
    title: '数量',
    dataIndex: 'practiceNum',
    key: 'practiceNum',
    width: 50,
  },
  {
    title: '实收金额',
    dataIndex: 'amount',
    key: 'amount',
    width: 78,
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '支付方式',
    dataIndex: 'chargeWay',
    key: 'chargeWay',
    width: 80,
  },
  {
    title: '收费员',
    dataIndex: 'chargerName',
    key: 'chargerName',
    width: 80,
  },
  {
    title: '收费时间',
    dataIndex: 'chargeDt',
    key: 'chargeDt',
    width: 165,
  },

  {
    title: '强训日期',
    dataIndex: 'practiceDay',
    key: 'practiceDay',
    width: 95,
  },
  {
    title: '考试次数',
    dataIndex: 'examTimes',
    key: 'examTimes',
    width: 78,
  },
  {
    title: '是否作废',
    scopedSlots: { customRender: 'cancelId' },
    key: 'cancelId',
    width: 78,
  },
  {
    title: '状态',
    dataIndex: 'statusName',
    key: 'statusName',
    width: 60,
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    fixed: 'right',
    width: 180,
  },
]
import printTicket from '../../components/PrintTicket'
//import TrainList from '../components/TrainList'
import RecordGrade from '../components/RecordGrade'
//import UploadFile from '../components/UploadFile'
import InputLimit from '../../components/InputLimit'
import getCard from '../../components/GetCard'
export default {
  name: 'hoststulist',
  components: {
    printTicket,
    //TrainList,
    RecordGrade,
    //UploadFile,
    InputLimit,
    getCard,
  },
  props: {},

  data() {
    return {
      filter: {
        pageNum: 1,
        pageSize: this.pageSize,
        cdExamDayFrom: null,
        cdExamDayTo: null,
        examSpotId: this.$store.state.curSpotId,
        cdExamPass: '',
        cdStatusId: '',
        cdChargeWay: '',
        cdIsMultiExam: '',
      },
      stuList: [],
      roleColums,
      total: 0,
      totalMoney: '',
      passRate: '',
      totalPracticeNum: '',
      showPrint: false,
      stu: [],
      showGrade: false,
      curStu: {},
      hotList: [],
      saleList: [],
      showGetCard: true,
    }
  },
  computed: {
    cdCardChange() {
      return this.filter.cdIdCard
    },
  },
  watch: {
    cdCardChange(newValue) {
      console.log('newValue', newValue)
      if (newValue) {
        this.showGetCard = false
      } else {
        this.showGetCard = true
      }
    },
  },
  deactivated() {
    this.showGetCard = false
  },
  activated() {
    this.getStuList()
    this.getHotList()
    this.getSaleList()
    this.showGetCard = true
  },
  methods: {
    getCardKey(info) {
      this.filter.cdIdCard = info.card
      this.filter.cdStudentName = info.name
      this.filter.pageNum = 1
      this.getStuList()
    },
    exportStuTxt() {
      this.filter.cdOutIdCardOnly = true
      this.$downTxt(this.$url.导出强训学员, this.filter, '预约学员Txt')
    },
    //业务员列表
    getSaleList() {
      this.$post(this.$url.业务员列表, {
        examSpotId: this.$store.state.curSpotId,
      })
        .then((data) => {
          this.saleList = data.data.list
        })
        .catch(() => {})
    },
    //强训套餐列表
    getHotList() {
      this.$post(this.$url.强训套餐列表, {
        examSpotId: this.$store.state.curSpotId,
      })
        .then((data) => {
          this.hotList = data.data.list
        })
        .catch(() => {})
    },
    exportStu() {
      this.filter.cdOutIdCardOnly = false
      this.$downExe(this.$url.导出强训学员, this.filter, '收费管理')
    },
    printStu(item) {
      console.log('this.$store.state.billNo', this.$store.state.billNo)
      this.stu.push({
        billNo: item.billNo,
        billTitle: this.$store.state.billNo,
        name: item.studentName,
        idCard: item.idCard,
        practiceDate: item.practiceDay,
        car: item.carHotCode,
        turn: item.practiceNum,
        school: '没有驾校',
        remark: item.remark,
        hotMealName: item.hotMealName,
      })
      this.showPrint = true
      setTimeout(() => {
        this.stu = []
        this.showPrint = false
      }, 3000)
    },
    changPage(page) {
      this.filter.pageNum = page
      this.getStuList()
    },
    cancelPay(item) {
      this.$confirm({
        title: '是否确认取消？',
        content: `确认取消之后，本次的强训失效！`,
        onOk: () => {
          this.$post(this.$url.取消强训, {
            id: item.id,
            examSpotId: this.$store.state.curSpotId,
          })
            .then((data) => {
              this.$tips('取消成功', '本次强训作废成功！', 'success')
              this.getStuList()
            })
            .catch(() => {
              this.$tips('取消失败', '请重新操作！', 'error')
            })
        },
      })
    },
    recordGrade(item) {
      this.showGrade = true
      this.curStu.examScore = item.examScore
      this.curStu.examPass = item.examPass
      this.curStu.id = item.id
    },
    clearFilter() {
      this.filter = {
        pageNum: 1,
        pageSize: this.pageSize,
        cdExamDayFrom: null,
        cdExamDayTo: null,
        examSpotId: this.$store.state.curSpotId,
        cdExamPass: '',
        cdStatusId: '',
        cdChargeWay: '',
        cdIsMultiExam: '',
        cdIdCard: '',
      }
      this.getStuList()
    },
    getStuList() {
      if (!this.filter.examSpotId) return
      if (this.filter.cdExamDayFrom)
        this.filter.cdExamDayFrom = this.$moment(
          this.filter.cdExamDayFrom,
        ).format('YYYY-MM-DD')
      if (this.filter.cdExamDayTo)
        this.filter.cdExamDayTo = this.$moment(this.filter.cdExamDayTo).format(
          'YYYY-MM-DD',
        )
      if (this.filter.cdChargeDayFrom)
        this.filter.cdChargeDayFrom = this.$moment(
          this.filter.cdChargeDayFrom,
        ).format('YYYY-MM-DD')
      if (this.filter.cdChargeDayTo)
        this.filter.cdChargeDayTo = this.$moment(
          this.filter.cdChargeDayTo,
        ).format('YYYY-MM-DD')
      this.$post(this.$url.强训学员, this.filter)
        .then((data) => {
          this.stuList = data.data.list
          if (this.stuList.length) {
            this.total = data.data.total
            this.totalMoney = data.data.stat.totalAmount
            this.passRate = data.data.stat.passRate
            this.totalPracticeNum = data.data.stat.totalPracticeNum
          } else {
            this.total = 0
            this.totalMoney = 0
            this.passRate = 0
            this.totalPracticeNum = 0
          }
        })
        .catch(() => {})
    },
  },
}
</script>
<style scoped>
.total-num {
  margin: 0 30px 0 3px;
  font-size: 18px;
}
.total-con {
  font-size: 16px;
  position: relative;
  top: -15px;
}
.flex {
  margin: 5px 0;
  padding: 0 5px;
}
.act-con {
  padding: 5px 0 0;
}
.act-con button {
  margin: 5px 3px;
}
.filter {
  height: auto;
  margin-bottom: 8px;
  line-height: 30px;
  padding-bottom: 10px;
}
.type-name {
  height: 30px;
  width: 70px;
  top: 0;
  margin-right: 0;
}
</style>
