<template>
  <div>
    <a-modal
      title="录入成绩"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <p class="flex">
        <label for="" class="type-name">成绩</label>
        <a-input class="flex1" v-model="info.examScore" placeholder="成绩0-100" :maxLength="3"></a-input>
      </p>
      <p class="flex">
        <label for="" class="type-name">是否合格</label>
        <a-select v-model="info.examPass" class="flex1">
          <a-select-option value="">
            空
          </a-select-option>
          <a-select-option value="合格">
            合格
          </a-select-option>
          <a-select-option value="不合格">
            不合格
          </a-select-option>
          <a-select-option value="缺考">
            缺考
          </a-select-option>
        </a-select>
      </p>
    </a-modal>
  </div>
</template>
<script>
export default {
  props:{
    stu:{
      type:Object,
      default:{}
    },
  },
  data(){
    return{
      visible:true,
      info:{
        examPass:''
      },
    }
  },
  mounted(){
    this.info.examScore = this.stu.examScore
    this.info.examPass = this.stu.examPass
     this.$forceUpdate()
  },
  methods:{
    handleOk(){
      this.$post(this.$url.成绩录入,{
        examSpotId:this.$store.state.curSpotId,
        id:this.stu.id,
        examScore:this.info.examScore,
        examPass:this.info.examPass
      }).then(data => {
        this.$emit('hideGrade')
      }).catch(() => {
          
      });
    },
    handleCancel(){
      this.$emit('hideGrade')
    }
  }
}
</script>