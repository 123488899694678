<template>
  <div>
    <AddHotStu v-if="visible" @hideAdd=" visible=false " />
    <HotStuList v-else @showAdd = "showAdd" />
     <!--  添加角色  -->
  </div>
</template>
<script>
import AddHotStu from './components/AddHotStu'
import HotStuList from './components/HotStuList'
export default {
   components:{
      AddHotStu,
      HotStuList
    },
  props:{
  },
  data() {
    return {
      visible:false,
      
    }
  },
  mounted(){
  },
  methods: {
    showAdd(){
      this.visible = true
    }
  },
}
</script>
<style scoped>
.sed-nav{
  display:block;
  padding-left:0px;
  margin:8px 0;
}
.shousuo{
  margin-right:5px;
}
.second-nav{
  padding-left:30px;
  display: block;
}
.thr-nav{
  padding-left:0px;
  display:block;
  margin:8px 0;
}
</style>